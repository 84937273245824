import React from "react";
import {
  CloudUploadIcon,
  DocumentReportIcon,
  GiftIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'

export default function Example() {
  const items = [
    {
      name: "Cardholder & Merchant",
      description: <span>Access our Australia-based staff, trained to handle all cardholder and merchant requirements.</span>,
      icon: UserGroupIcon,
    },
    {
      name: "Funds Held in Trust",
      description: <span>Funds are securely held in a separate trust account, with our banking partners, to protect clients and consumers.</span>,
      icon: ShieldCheckIcon,
    },
    {
      name: "Remote Access",
      description: <span>All our platforms have remote diagnosis meaning in the unlikely event there&rsquo;s a problem we know before you do.</span>,
      icon: CloudUploadIcon,
    },
    {
      name: "Reward and Incentivize",
      description: <span>Staff, Partners and Customers: Appreciate and recognise those who build your success.</span>,
      icon: GiftIcon,
    },
    {
      name: "Balance Enquiry Options",
      description: <span>Mobile , web-based, and IVR options to access your balance and transaction history.</span>,
      icon: QuestionMarkCircleIcon,
    },
    {
      name: "Detailed Reporting",
      description: <span>Real-time reporting to always know where and when gift cards are spent.</span>,
      icon: DocumentReportIcon,
    },
  ]

  return (
    <section className="relative bg-white py-16 sm:py-24">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-6xl">
        <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">24/7/365</h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          WAIVPAY&rsquo;s Full-Service Support
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          Our end-to-end support eliminates hassle and complexity in gifting and loyalty
          for better engagement and greater traffic from your customers and shoppers.
        </p>
        <div className="mt-12">
          <div className="relative z-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {items.map((item, idx) => (
              <div key={idx} className="pt-6">
                <div className="flow-root bg-gray-100 h-full rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">
                        <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{item.name}</h3>
                    <p className="mt-5 text-base text-gray-500">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div aria-hidden="true" className="absolute z-0 inset-x-0 bottom-0 h-48 bg-gradient-to-b from-white to-gray-100"></div>
        </div>
      </div>
    </section>
  );
}

